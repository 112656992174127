import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

interface MarkdownRendererProps {
  content: string;
}

const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({ content }) => {

  const preprocessContent = (text: string) => {
    return text
    .replace(/^"|"$/g, '')
    .replace(/\\n/g, '\n')
    .replace(/\\"/g, '"')
    .replace(/\\u([0-9a-fA-F]{4})/g, (match, p1) => String.fromCharCode(parseInt(p1, 16)));
  };

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        p: ({ node, ...props }) => <p className="mb-4" {...props} />,
        h1: ({ node, ...props }) => <h1 className="text-2xl font-bold mb-2" {...props} />,
        h2: ({ node, ...props }) => <h2 className="text-xl font-bold mb-2" {...props} />,
        h3: ({ node, ...props }) => <h3 className="text-lg font-bold mb-2" {...props} />,
        ul: ({ node, ...props }) => <ul className="list-disc list-inside mb-4" {...props} />,
        ol: ({ node, ...props }) => <ol className="list-decimal list-inside mb-4" {...props} />,
        li: ({ node, ...props }) => <li className="mb-1" {...props} />,
        // code: ({ node, inline, ...props }) =>
        //   inline ? (
        //     <code className="bg-gray-100 rounded px-1" {...props} />
        //   ) : (
        //     <pre className="bg-gray-100 rounded p-2 overflow-x-auto">
        //       <code {...props} />
        //     </pre>
        //   ),
      }}
    >
      {preprocessContent(content)}
    </ReactMarkdown>
  );
};

export default MarkdownRenderer;