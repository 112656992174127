"use client";
import { Flex, Text, Box, TextField, IconButton, Spinner, Skeleton, Card, Callout } from '@radix-ui/themes';
import { PaperPlaneIcon } from '@radix-ui/react-icons'
import { useState, KeyboardEvent } from 'react';
import { ask } from '@/app/lib/proteus_api';
import  MarkdownRenderer  from '@/app/components/markdown_render';

export default function MyApp() {
  const [inputText, setInputText] = useState('');
  const [apiResult, setApiResult] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const notLoadingAndResult = !isLoading && apiResult;
  
  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const result = await ask(inputText);
      console.log('API Result:', result);
      if (typeof result === 'string') {
        setApiResult(result);
      } else {
        setApiResult('Received an unexpected response from the API.');
      }
      // setApiResult(result); // Assuming ask() returns a string
    } catch (error) {
      console.error('Error:', error);
      setApiResult('An error occurred while fetching the result.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCalloutClick = (text: string) => {
    setInputText(text);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !isLoading) {
      handleSubmit();
    }
  };

  return (
    <Flex height="100%" direction="column" justify="between" align-items="center" p="4" gap="6">
      <Card>
        <Flex direction="column" gap="4">
          <Flex flexGrow="1" gap="2" justify="between" m="4" direction={"row"}>
            <Box flexGrow={"0"} width="40%">
              <Text align="center" m="4" size="5" weight={"bold"}>ProteusAI is your marine weather routing and trip advisor. Where are you sailing next?</Text>
            </Box>
            <Box width="70%">
              <TextField.Root
                placeholder="I want to go sailing in ..."
                size="3"
                color='sky'
                style={{ width: '100%' }}
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                onKeyDown={handleKeyDown}
              >
                <TextField.Slot side='right'>
                  <IconButton variant="soft" onClick={handleSubmit} disabled={isLoading}>
                    <Spinner loading={isLoading}>
                      <PaperPlaneIcon height="16" width="16" />
                    </Spinner>
                  </IconButton>
                </TextField.Slot>
              </TextField.Root>
              <Text size="1" ml="4" as="div">Note: AI-generated content may contain errors. Do not use these results as the basis of your marine navigation decisions.</Text>
            </Box>
          </Flex>
          <Flex direction={"row"} gap="3">
            <Callout.Root variant='surface' onClick={() => handleCalloutClick("I want to go sailing in Marseilles tomorrow. What will the weather be?")}>
              <Callout.Text>
                I want to go sailing in Marseilles tomorrow. What will the weather be?
              </Callout.Text>
            </Callout.Root>
            <Callout.Root variant='surface' onClick={() => handleCalloutClick("Next week, I have chartered a 35ft yacht in the Greek Islands. What route would you suggest given the weather?")}>
              <Callout.Text>
                Next week, I have chartered a 35ft yacht in the Greek Islands. What route would you suggest given the weather?
              </Callout.Text>
            </Callout.Root>
            <Callout.Root variant='surface' onClick={() => handleCalloutClick("I want to cross the Atlantic next month. What will the situation be like?")}>
              <Callout.Text>
                I want to cross the Atlantic next month. What will the situation be like?
              </Callout.Text>
            </Callout.Root>
        </Flex>
        </Flex>
            </Card>

      <Box flexGrow="3" p="6">
        <Skeleton loading={isLoading}>
          {notLoadingAndResult &&
            <Card>
                <Text size="3">
                  <MarkdownRenderer content={apiResult} />
                </Text>
            </Card>
          }
          {isLoading &&
            <Text size="3">Your result will show here shortly.</Text>
          }
        </Skeleton>
      </Box>
    </Flex>
  );
}